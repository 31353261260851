.topMatchesSportsListWrapper {
  padding: 8px !important;
  background-color: var(--v3-black-4);
  color: var(--v3-text-color);
  flex-direction: column;

  display: flex;
  gap: 4px;

  &__noItem {
    margin-bottom: 0;

    &.medium {
      padding: 48px 32px;
    }
  }
}

.sportItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--v3-black-2);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;

  .v3-icon {
    color: var(--v3-black-45);
  }

  &__iconName {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &--active {
    background-color: var(--v3-black-6);
    color: var(--v3-primary-color);
  }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;