.menuWrapperMobile {
  background-color: var(--v3-black-0);
  border-bottom: 1px solid var(--v3-black-6);
  overflow: hidden;
  height: 56px;
  width: 100%;
  padding: 0 4px;

  &--small {
    height: 49px;
  }

  &--borderRadius {
    border-radius: 4px;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 4px 0;
    height: 80px;
    gap: 4px;
  }
}

.menuItemMobile {
  color: var(--v3-text-color);
  cursor: pointer;
  height: 60px;
  min-width: 68px;
  display: flex;
  flex-shrink: 0;
  padding: 8px;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 0;

  &--active {
    background-color: var(--v3-black-4);
    position: sticky;
    right: 0;
    left: 0;
    z-index: 1;
    &:after {
      content: '';
      background: var(--v3-primary-5);
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -16px;
      height: 2px;
      width: 32px;
      border-radius: 1px;
    }

    &--row {
      height: 40px;
      flex-direction: row;
      background: var(--v3-black-0);

      & > div {
        color: var(--v3-text-color);
        font-size: 13px;
      }

      &::after {
        content: '';
        background: var(--v3-primary-5);
        position: absolute;
        bottom: 0;
        height: 2px;
        border-radius: 1px;
        left: 0;
        width: 100%;
        margin-left: 0;
      }

      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }

  &__iconWrapper {
    position: relative;
    display: flex;
    margin-bottom: 4px;
  }

  &__countBadge {
    background-color: var(--v3-black-100);
    color: var(--v3-black-0);
    position: absolute;
    border-radius: 7px;
    line-height: 10px;
    font-size: 8px;
    padding: 0 5px;
    right: -7px;
    top: -5px;
    z-index: 1;
  }

  &__menuLabel {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
  }

  .customSkeleton-title {
    margin: 5px 0 !important;
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;