.primary-color {
  color: var(--v3-primary-color) !important;
}

.primary-color-1 {
  color: var(--v3-primary-1);
}

.primary-color-2 {
  color: var(--v3-primary-2);
}

.primary-color-3 {
  color: var(--v3-primary-3);
}

.primary-color-4 {
  color: var(--v3-primary-4);
}

.primary-color-5 {
  color: var(--v3-primary-5);
}

.primary-color-6 {
  color: var(--v3-primary-6);
}

.primary-color-7 {
  color: var(--v3-primary-7);
}

.primary-color-8 {
  color: var(--v3-primary-8);
}

.primary-color-9 {
  color: var(--v3-primary-9);
}

.primary-color-10 {
  color: var(--v3-primary-10);
}

.black-100 {
  color: var(--v3-black-100);
}

.black-85 {
  color: var(--v3-black-85);
}

.black-65 {
  color: var(--v3-black-65);
}

.black-45 {
  color: var(--v3-black-45);
}

.black-25 {
  color: var(--v3-black-25);
}

.black-15 {
  color: var(--v3-black-15);
}

.black-6 {
  color: var(--v3-black-6);
}

.black-4 {
  color: var(--v3-black-4);
}

.black-3 {
  color: var(--v3-black-3);
}

.black-2 {
  color: var(--v3-black-2);
}

.black-1 {
  color: var(--v3-black-1);
}

.black-0 {
  color: var(--v3-black-0);
}

.bg-cyan-base {
  background-color: var(--v3-cyan-base);
}

.bg-red-5 {
  background-color: var(--v3-red-5);
}

.text-color {
  color: var(--v3-text-color);
}

.text-color--white {
  color: var(--v3-white) !important;
}

.text-color-secondary {
  color: var(--v3-black-45);
}

.text-color-secondary--fixed {
  color: var(--v3-text-color-secondary-fixed) !important;
}

.text-color-inverse {
  color: var(--v3-black-0);
}

.bg-primary-color {
  background-color: var(--v3-primary-color);
}

.bg-primary-color-1 {
  background-color: var(--v3-primary-1);
}

.bg-primary-color-2 {
  background-color: var(--v3-primary-2);
}

.bg-primary-color-3 {
  background-color: var(--v3-primary-3);
}

.bg-primary-color-4 {
  background-color: var(--v3-primary-4);
}

.bg-primary-color-5 {
  background-color: var(--v3-primary-5);
}

.bg-primary-color-6 {
  background-color: var(--v3-primary-6);
}

.bg-primary-color-7 {
  background-color: var(--v3-primary-7);
}

.bg-primary-color-8 {
  background-color: var(--v3-primary-8);
}

.bg-primary-color-9 {
  background-color: var(--v3-primary-9);
}

.bg-primary-color-10 {
  background-color: var(--v3-primary-10);
}

.bg-black-100 {
  background-color: var(--v3-black-100);
}

.bg-black-85 {
  background-color: var(--v3-black-85);
}

.bg-black-65 {
  background-color: var(--v3-black-65);
}

.bg-black-45 {
  background-color: var(--v3-black-45);
}

.bg-black-25 {
  background-color: var(--v3-black-25);
}

.bg-black-15 {
  background-color: var(--v3-black-15);
}

.bg-black-6 {
  background-color: var(--v3-black-6);
}

.bg-black-4 {
  background-color: var(--v3-black-4);
}

.bg-black-2 {
  background-color: var(--v3-black-2);
}

.bg-black-1 {
  background-color: var(--v3-black-1);
}

.bg-black-0 {
  background-color: var(--v3-black-0);
}
