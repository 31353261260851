@font-face {
  font-family: 'geomanist';
  src: url('./assets/fonts/geomanist/geomanist-regular-webfont.woff2')
      format('woff2'),
    url('./assets/fonts/geomanist/geomanist-regular-webfont.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.42px;
  font-display: swap;
}

@font-face {
  font-family: 'geomanist';
  src: url('./assets/fonts/geomanist/geomanist-book-webfont.woff2')
      format('woff2'),
    url('./assets/fonts/geomanist/geomanist-book-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.42px;
  font-display: swap;
}

[class*='-list-content'],
[class*='-input-label'] {
  font-weight: 600;
}

[class*='-input-control'] {
  line-height: 1;
}


.grecaptcha-badge {
  display: none;
}

.v3-icon {
  line-height: 0;
  display: inline-block;
  text-align: center;
  align-self: center;
}

.GlobalIcon__skeleton {
  text-align: center;
  align-self: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

#fixed-footer {
  flex-shrink: 0;
}

.fixed-header--mobile [data-widget='BottomNavigation'] {
  display: none;
}

.fixed-header {
  position: fixed !important;
  z-index: 1013 !important;
  width: 100%;
  top: auto !important;
}

.fixed-header--mobile {
  position: fixed !important;
  z-index: 14 !important;
  width: 100%;
  top: auto !important;
}


.fixed-header--mobile.header-overlapped {
  top: 0 !important;
}

div::-webkit-scrollbar,
ul::-webkit-scrollbar,
section::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  border-radius: 6px;
}

div::-webkit-scrollbar-track-piece,
ul::-webkit-scrollbar-track-piece,
section::-webkit-scrollbar-track-piece {
  background: var(--v3-black-2);
  border-radius: 6px;
}

div::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb {
  height: 5px;
  width: 6px;
  background: var(--v3-black-25);
  border-radius: 6px;
  outline: 0;
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;