.authSectionsButtonsWrapper {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1;

  [class*='-btn'] {
    padding: 4px 8px;
  }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;