.x-button {
  border-radius: 6px !important;

  &--green {
    background-color: var(--v3-cyan-base) !important;
    border-color: var(--v3-cyan-base) !important;
    color: var(--v3-text-color-inverse) !important;

    &:active:not(.x-button--disabled),
    &:hover:not(.x-button--disabled),
    &:focus:not(.x-button--disabled) {
      background-color: var(--v3-cyan-5) !important;
      border-color: var(--v3-cyan-5) !important;
      color: var(--v3-text-color-inverse) !important;
    }
  }

  &--cancel {
    background-color: var(--v3-black-6) !important;
    border-color: var(--v3-black-6) !important;
    color: var(--v3-text-color) !important;

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(.predefined--button):not(.x-button--disabled) {
        background-color: var(--v3-black-15) !important;
        border-color: var(--v3-black-15) !important;
        color: var(--v3-text-color) !important;
      }
    }
  }

  &--fun {
    color: var(--v3-black-85) !important;
    border-color: var(--v3-black-85) !important;

    &:active:not(.x-button--disabled),
    &:hover:not(.x-button--disabled),
    &:focus:not(.x-button--disabled) {
      color: var(--v3-primary-6) !important;
      border-color: var(--v3-primary-6) !important;
    }
  }

  &--link-primary.v3-btn {
    border: none;
    color: var(--v3-primary-color);
    border: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
    outline: none;
  }

  &--fullWidth {
    flex: 1;
    width: 100%;
  }

  &--disabled {
    background-color: var(--v3-black-6);
    color: var(--v3-text-color-secondary);
    border: 1px solid var(--v3-black-6);
  }

  &__actionBtn.v3-btn {
    width: 100%;
    padding: 8px;
    height: 36px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--v3-text-color) !important;
    background-color: var(--v3-black-4);
    border: 1px solid var(--v3-black-4) !important;
    font-size: 12px;
    border-radius: var(--v3-border-radius-xxs);
    white-space: nowrap;
    &:active,
    &:hover,
    &:focus {
      background-color: var(--v3-black-6);
      border-color: var(--v3-black-6);
    }
    & > .v3-icon {
      color: var(--v3-black-85);
      margin-right: 4px;
    }
  }
}

button.xButton__ {
  &small {
    height: 32px;
  }

  &medium {
    height: 36px;
  }

  &large {
    height: 40px;
  }

  &x-large {
    height: 44px;
  }

  &xx-large {
    height: 48px;
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;