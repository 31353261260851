.emptyDateBox {
  margin: 0 auto;
  background-color: rgba(191, 191, 191, 0.12);
  border-radius: 50%;
  margin-bottom: 8px;
  width: 156px;
  height: 156px;

  &__mobile {
    margin-top: 80px;
  }

  &__coupon-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 124px;
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;