.customSkeleton-image {
    width: 96px;
    height: 96px;
    line-height: 96px;

    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    background: rgba(190, 190, 190, 0.2);

    .v3-icon {
        font-size: inherit !important;
    }

    svg {
        width: 48px;
        height: 100%;
        max-width: 192px;
        line-height: 48px;
    }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;