.MobileSportsbookEventCollapseSkeleton {
  background-color: var(--v3-black-0);
  height: 39px;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 8px;

  .customSkeleton {
    display: flex;
    align-items: center;
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;