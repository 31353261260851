.sportsListHorizontal {
  .menuWrapperMobile {
    padding: 8px 4px;
    height: 76px;
  }
}

.sportsListVerticalSkeleton {
  overflow: auto;
  width: 100%;
  padding: 8px;
  background-color: var(--v3-black-0);
  border-radius: 6px;
  text-align: left
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;