@keyframes customSkeleton-loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

span.customSkeleton {
    &-element {
        display: inline-block !important;
        width: auto !important;
    }

    &-button {
        display: inline-block;
        vertical-align: top;
        background: rgba(190, 190, 190, 0.2);
        border-radius: 4px;
        width: 64px;
        min-width: 64px;
        height: 32px;
        line-height: 32px;

        &-sm {
            width: 48px;
            min-width: 48px;
            height: 24px;
            line-height: 24px;
        }

        &-lg {
            width: 80px;
            min-width: 80px;
            height: 40px;
            line-height: 40px;
        }
    }

    &-active {
        .customSkeleton-button {
            background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%,
                    rgba(129, 129, 129, 0.24) 37%,
                    rgba(190, 190, 190, 0.2) 63%);
            background-size: 400% 100%;
            animation: customSkeleton-loading 1.4s ease infinite;
        }
    }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;