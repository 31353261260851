.notification__management {
  &-title {
    color: var(--v3-primary-6);
    font-weight: 600;
    line-height: 1;
    font-size: 20px;
    padding-bottom: 8px;
    letter-spacing: 0.4px;
  }

  &-info {
    margin: 8px 0 24px;
    color: var(--v3-text-color);
  }
}

.switcher {
  &__text {
    margin-right: 8px;
    color: var(--v3-text-color);
  }

  &__container {
    display: flex;

    & > div {
      display: flex;
      align-items: center;
    }
  }
}

.manage__sms-switcher {
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid var(--v3-black-45);
}

.subscription__success_infoText {
  color: var(--v3-text-color);
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;