.sportsListCategoryComps {
  padding: 4px;

  &__compRowWrapper {
    margin: 0 0 4px 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background-color: var(--v3-black-0);
    padding: 0 8px 0;
    height: 32px;
    border-radius: 6px;

    &:hover {
      background-color: var(--v3-black-4);
    }
  }

  &__competitionNameWrapper {
    color: var(--v3-text-color);
    display: flex;
    align-items: center;
  }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;