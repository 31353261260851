.Competitions {
  &__FavoriteIconWrapper {
    margin-right: 8px;
    display: flex;
    align-items: center;

    &__liveEuropean {
      margin-right: 8px;
      display: flex;
      height: inherit;
      position: relative;
      align-items: center;

      &::after {
        content: '';
        height: 32px;
        border: 1px solid var(--v3-black-6);
        ;
        position: absolute;
        left: calc(100% + 8px);
      }
    }

    &__FavoriteIcon {
      color: var(--v3-black-45);

      &__active {
        color: var(--v3-primary-6);
      }
    }
  }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;