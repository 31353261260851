.sportCategoriesWrapper {
  background-color: var(--v3-black-0);
  border-bottom: 1px solid var(--v3-black-6);
  overflow: hidden;
  height: 48px;
  width: 100%;
  padding: 0 8px;
}

.sportCategoriesInner {
  overflow: auto;
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
}

.categoryItem {
  gap: 4px;
  height: 32px !important;
  border-radius: 6px;
  flex-direction: row;
  background-color: var(--v3-black-4);
  cursor: pointer;
  min-width: 50px;
  display: flex;
  flex-shrink: 0;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  font-size: 12px;
  line-height: 16px;
  color: var(--v3-text-color);
  letter-spacing: 0.1px;

  &--active {
    border: 1px solid var(--v3-primary-6);
    color: var(--v3-primary-6);
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;