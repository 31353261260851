.current-date {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 49%;
}

.current-date-desktop {
  font-size: 16px;
  top: 60%;
  
}

.current-date-mobile {
  font-size: 10px;
  top: 65%;
}

.text-color-fixed {
  color: var(--v3-text-color-fixed);
}

.GlobalIcon__skeleton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 2; // this is for hiding global icon content in case of loading (under the skeleton)
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;