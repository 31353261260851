html {
    --v3-white: #fff;
    --v3-red: #ff0000;
    --v3-background: #f5f5f9;
    --v3-blue-3: #e2deff;
    --v3-blue-5: #928cff;
    --v3-red-base: #f5222d;
    --v3-red-1: #fff1f0;
    --v3-red-5: #ff4d4f;
    --v3-red-3: #ff6262;
    --v3-cyan-base: #13c2c2;
    --v3-cyan-1: #e6fffb;
    --v3-cyan-5: #36cfc9;
    --v3-gold-base: #faad14;
    --v3-gold-1: #fffbe6;
    --v3-gold-5: #ffc53d;
    --v3-green-base: #52c41a;
    --v3-green-5: #73d13d;
    --v3-purple-base: #722ed1;
    --v3-yellow-base: #fadb14;
    --v3-yellow-7: #d4b106;
    --v3-primary-color: #6563ff;
    --v3-primary-1: #f3f0ff;
    --v3-primary-2: #f2f0ff;
    --v3-primary-3: #e2deff;
    --v3-primary-4: #bbb5ff;
    --v3-primary-5: #928cff;
    --v3-primary-6: #6563ff;
    --v3-primary-7: #4a4cd9;
    --v3-primary-8: #343ab3;
    --v3-primary-9: #222b8c;
    --v3-primary-10: #172166;
    --v3-primary-11: #0092FF;
    --v3-black: #000000;
    --v3-black-100: #000000;
    --v3-black-85: #262626;
    --v3-black-65: #595959;
    --v3-black-45: #8c8c8c;
    --v3-black-25: #bfbfbf;
    --v3-black-15: #d9d9d9;
    --v3-black-6: #f0f0f0;
    --v3-black-4: #f5f5f5;
    --v3-black-2: #fafafa;
    --v3-black-1: #fbfbfb;
    --v3-black-0: #ffffff;
    --v3-error-color: #ff4d4f;
    --v3-success-color: #52c41a;
    --v3-success-color-light: #31cdc7;
    --v3-processing-color: #6563ff;
    --v3-shadow-1-up: 0 -2px 4px rgba(0, 0, 0, 0.1);
    --v3-shadow-1-down: 0 2px 4px rgba(0, 0, 0, 0.1);
    --v3-shadow-2: 0 1px 2px rgba(0, 0, 0, 0.08);
    --v3-shadow-strong: 0px 3px 6px -1px rgba(98, 98, 98, 0.08);
    --v3-shadow-long: 0px 8px 30px 0 rgba(38, 38, 38, 0.07);
    --v3-text-color-fixed: #262626;
    --v3-text-color-secondary-fixed: #fefefe;
    --v3-divider-color: #919191;
    --v3-color-on-primary: #fff;
    --v3-text-color: var(--v3-black-85);
    --v3-text-color-secondary: var(--v3-black-65);
    --v3-text-color-inverse: var(--v3-black-0);
    --v3-black-15-fixed: #d9d9d9;
    --v3-progress-gray-color: #cdcecf;
    --v3-progress-blue-color: #577bfb;
    --v3-notification-popup-z-index: 10000010;
    --v3-border-radius-xxs: 6px;
    --v3-border-radius-xs: 10px;
    --v3-border-radius-s: 12px;
    --v3-border-radius-m: 16px;
}

html[data-theme='v3-light'] {
    --v3-black-100: #070712;
    --v3-black-85: #2c2c36;
    --v3-black-65: #5e5e65;
    --v3-black-45: #8f8f95;
    --v3-black-25: #c1c1c4;
    --v3-black-15: #dadadc;
    --v3-black-6: #f0f0f1;
    --v3-black-4: #f5f5f6;
    --v3-black-2: #f8f8f8;
    --v3-black-1: #fafafa;
    --v3-black-0: #ffffff;
    /*remove*/
    /*--v3-primary-2: #f2f0ff*/
}

html[data-theme='v3-dark'] {
    --v3-black-100: #ffffff;
    --v3-black-85: #d9d9d9;
    --v3-black-65: #a8a8a8;
    --v3-black-45: #737373;
    --v3-black-25: #4d4d4d;
    --v3-black-15: #404040;
    --v3-black-6: #333333;
    --v3-black-4: #262626;
    --v3-black-2: #1f1f1f;
    --v3-black-1: #171717;
    --v3-black-0: #0f0f0f;
    /*remove*/
    /*--v3-primary-2: #172166*/
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;