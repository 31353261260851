.competitionFavoriteMatchesCountIndicator {
  padding: 6px;
  border-radius: 6px;
  background: var(--v3-black-0);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  direction: ltr;
  &__icon {
    color: var(--v3-primary-color);
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;