.ChangeTheme {
  width: 60px;
  height: 32px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  &__IconWrapper {
    font-size: 20px;
    color: var(--v3-primary-color);
    border-radius: 40px;
    background-color: var(--v3-black-6);

    &__mobile {
      background-color: var(--v3-black-0);
    }

    &--darkMode {
      color: var(--v3-white);
      background-color: var(--v3-primary-color);
    }
  }
}

.ChangeThemeSkeleton {
  min-width: 60px !important;
  width: 60px !important;
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;