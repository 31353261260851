@keyframes customSkeleton-loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.customSkeleton {
    width: 100%;
    display: table;

    &__withAvatar {
        display: flex;
        gap: 12px;
    }

    /* START STANDART CONTENT */
    &-content {
        width: 100%;
        display: table-cell;
        vertical-align: top;
    }

    &__header {
        display: table-cell;
        vertical-align: top;
    }

    &-title {
        width: 38%;
        height: 16px;
        margin-top: 16px;
        border-radius: 4px;
        background: rgba(190, 190, 190, 0.2);

        &-avatar {
            width: 100%;
            margin-top: 12px;
        }

        &+.customSkeleton-paragraph {
            margin-top: 24px;
        }
    }

    &-paragraph {
        padding: 0;

        li {
            width: 100%;
            height: 16px;
            list-style: none;
            border-radius: 4px;
            background: rgba(190, 190, 190, 0.2);

            &+li {
                margin-top: 16px;
            }

            &:last-child:not(:first-child) {
                width: 61%;
            }
        }
    }

    /* END STANDART CONTENT */


    /* START ACTIVE CONTENT */
    &-active {
        .customSkeleton {
            &-title,
            &-paragraph>li {
                background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%,
                        rgba(129, 129, 129, 0.24) 37%,
                        rgba(190, 190, 190, 0.2) 63%);
                background-size: 400% 100%;
                animation: customSkeleton-loading 1.4s ease infinite;
            }
        }
    }

    /* END ACTIVE CONTENT */

    &-round {
        .customSkeleton {
            &-title,
            &-content,
            &-paragraph>li {
                border-radius: 100px;
            }
        }
    }
}
@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;