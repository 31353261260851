.menuWrapperDesktop {
  background-color: var(--v3-black-0);
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  height: 96px;
  width: 100%;
  padding: 0 4px 0 12px;

  .menuWrapperDesktop {
    padding: 0;
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    .swiper-slide > div {
      display: flex;
    }
  }
}

.menuItemDesktop {
  position: relative !important;
  cursor: pointer;
  min-width: 96px;
  width: 110px;
  height: 80px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: var(--v3-black-0);
  color: var(--v3-text-color);

  .sportFavoriteButton {
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }

  &:hover {
    background-color: var(--v3-black-2);
    .sportFavoriteButton {
      opacity: 1;
    }
  }

  &--active {
    position: sticky;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: var(--v3-black-4);
    &::after {
      content: '';
      background: var(--v3-primary-5);
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -16px;
      height: 2px;
      width: 32px;
      border-radius: 1px;
    }
  }

  &__countBadge {
    background-color: var(--v3-black-100);
    color: var(--v3-black-0);
    position: absolute;
    border-radius: 7px;
    line-height: 14px;
    font-size: 11px;
    padding: 0 5px;
    right: auto;
    top: 10px;
    z-index: 1;
    transform: translateX(20px);
  }

  &__name {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 8px 0 8px;
    display: inline-block;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 110px;
    direction: ltr;
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;