.competitions-by-lists {
  width: 100%;
  background: var(--v3-black-4);
  padding: 4px 8px 0;
  border: none !important;

  &__content {
    width: 100%;

    .competition-item-wrapper {
      width: 100%;
      background: var(--v3-black-0);
      border-radius: 6px;
      overflow: hidden;
      height: 36px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      a {
        margin: 0;
      }

      .competitions-by-lists {
        padding-top: 8px;
      }
    }

    .competition-item-wrapper:nth-child(1) {
      .competitionWithTooltip {
        border: none;
      }
    }

    .Competitions__FavoriteIconWrapper {
      line-height: initial;
      border-right: solid 1px var(--v3-black-6);
      padding-right: 6px;
    }

    .competitionWithTooltip__name {
      padding-left: 4px;
    }
  }

  .competition-item-skeleton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 5px;

    &__content {
      width: 100%;
      border-top: solid 1px var(--v3-black-6);
      padding: 7px 3px 6px 7px;
      display: flex;
      justify-content: space-between;

      .favorite,
      .sport,
      .region {
        width: 20px;
        padding-right: 4px;
      }

      .customSkeleton-title {
        margin: 0;
      }

      .favoriteSportName {
        display: flex;
        width: 100%;

        .customSkeleton-title {
          width: 90%;
          margin: 2px auto;
        }

        & + .customSkeleton .customSkeleton-title-avatar {
          margin-top: 0;
          height: 0;
        }

        .favorite {
          border-right: solid 1px var(--v3-black-6);
        }
        .sport {
          padding-left: 4px;
        }
      }
    }

    &:nth-child(1) {
      &__content {
        border: none;
      }
    }
  }
  .competition-item-skeleton:nth-child(1) {
    .competition-item-skeleton__content {
      border: none;
    }
  }
}

@font-family: geomanist;@pagination-font-family: @font-family;@border-radius-base: 4px;@table-padding-horizontal: 24px;@input-padding-vertical-base: 3px;@ant-prefix: v3;@btn-font-size-lg: 14px;@font-size-heading: 14px;@font-size-caption: 14px;@modal-font-size-heading: 16px;@modal-button-font-size: 14px;@modal-button-height: 40px;